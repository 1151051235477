<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6"><v-text-field hide-details label="* Turma:" v-model="registro.turma" /></v-col>
                            <v-col cols="12" sm="6"><SelectFranquia hide-details label="* Franquia:" v-model="registro.id_franquia" /></v-col>
                            <v-col cols="12" sm="6"><v-select hide-details label="* Curso:" :items="selectCurso" v-model="registro.id_curso" /></v-col>
                            <v-col cols="12" sm="6"><v-autocomplete hide-details label="* Professor:" :items="selectUsuarios" v-model="registro.id_usuario_professor_responsavel" /></v-col>
                            <v-col cols="12" sm="6"><DatePickerDialogInput hide-details label="* Início das aulas:" v-model="registro.dt_inicio" /></v-col>
                            <v-col cols="12" sm="6"><DatePickerDialogInput hide-details label="* Conclusão das aulas:" v-model="registro.dt_fim" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" v-if="!this.id_turma">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title>Programar aulas</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="4"><v-checkbox v-model="registro.domingo" hide-details label="Domingo"/></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.domingo" hide-details label="* Início:" v-model="registro.domingo_horario_inicio" v-mask="'##:##'" placeholder="Ex: 18:30" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.domingo" hide-details label="* Conclusão:" v-model="registro.domingo_horario_fim" v-mask="'##:##'" placeholder="Ex: 22:30" /></v-col>
                            <v-col cols="12" sm="4"><v-checkbox v-model="registro.segunda" hide-details label="Segunda"/></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.segunda" hide-details label="* Início:" v-model="registro.segunda_horario_inicio" v-mask="'##:##'" placeholder="Ex: 18:30" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.segunda" hide-details label="* Conclusão:" v-model="registro.segunda_horario_fim" v-mask="'##:##'" placeholder="Ex: 22:30" /></v-col>
                            <v-col cols="12" sm="4"><v-checkbox v-model="registro.terca" hide-details label="Terça"/></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.terca" hide-details label="* Início:" v-model="registro.terca_horario_inicio" v-mask="'##:##'" placeholder="Ex: 18:30" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.terca" hide-details label="* Conclusão:" v-model="registro.terca_horario_fim" v-mask="'##:##'" placeholder="Ex: 22:30" /></v-col>
                            <v-col cols="12" sm="4"><v-checkbox v-model="registro.quarta" hide-details label="Quarta"/></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.quarta" hide-details label="* Início:" v-model="registro.quarta_horario_inicio" v-mask="'##:##'" placeholder="Ex: 18:30" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.quarta" hide-details label="* Conclusão:" v-model="registro.quarta_horario_fim" v-mask="'##:##'" placeholder="Ex: 22:30" /></v-col>
                            <v-col cols="12" sm="4"><v-checkbox v-model="registro.quinta" hide-details label="Quinta"/></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.quinta" hide-details label="* Início:" v-model="registro.quinta_horario_inicio" v-mask="'##:##'" placeholder="Ex: 18:30" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.quinta" hide-details label="* Conclusão:" v-model="registro.quinta_horario_fim" v-mask="'##:##'" placeholder="Ex: 22:30" /></v-col>
                            <v-col cols="12" sm="4"><v-checkbox v-model="registro.sexta" hide-details label="Sexta"/></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.sexta" hide-details label="* Início:" v-model="registro.sexta_horario_inicio" v-mask="'##:##'" placeholder="Ex: 18:30" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.sexta" hide-details label="* Conclusão:" v-model="registro.sexta_horario_fim" v-mask="'##:##'" placeholder="Ex: 22:30" /></v-col>
                            <v-col cols="12" sm="4"><v-checkbox v-model="registro.sabado" hide-details label="Sábado"/></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.sabado" hide-details label="* Início:" v-model="registro.sabado_horario_inicio" v-mask="'##:##'" placeholder="Ex: 18:30" /></v-col>
                            <v-col cols="12" sm="4"><v-text-field v-if="registro.sabado" hide-details label="* Conclusão:" v-model="registro.sabado_horario_fim" v-mask="'##:##'" placeholder="Ex: 22:30" /></v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'
import DatePickerDialogInput from "@/Views/Estrutura/Form/DatePickerDialogInput"
import SelectFranquia from "@/Views/Estrutura/Form/SelectFranquia.vue";

export default {
    name: "TurmaForm",
    components: {SelectFranquia, DatePickerDialogInput},
    props : ['id_turma'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null,

            selectCurso : [],
            selectStatus : [],
            selectUsuarios : [],
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl', 'usuarioSessao'])
    },
    methods : {
        getTurma() {
            axios.post(`${this.apiUrl}turma/get`, {id_turma : parseInt(this.id_turma)}).then((res) => {
                if(res.data.erro) {
                    this.$router.push({path : '/turma'})
                }
                this.registro = {
                    ...res.data,
                    data_nascimento : this.dataPt(res.data.data_nascimento)
                }
            })
        },
        selectCursoListar() {
            axios.post(`${this.baseUrl}curso/list`, {}).then((res) => {
                this.selectCurso = res.data.lista.map((e) => {return {
                    value : parseInt(e.id_curso),
                    text : e.curso
                }})
            })
        },
        selectUsuariosListar() {
            return axios.post(`${this.baseUrl}usuario/listar`, {limit:9999, id_franquia : this.registro.id_franquia}).then((res) => {
                this.selectUsuarios = res.data.lista.map((rt) => {
                    return {value : parseInt(rt.id_usuario), text : rt.usuario}
                })
            })
        },
        salvar() {
            if(!this.id_turma) {
                return axios.post(`${this.apiUrl}turma/nova`, {...this.registro}).then( (res) => {
                    this.erro = null
                    this.$router.push({path : '/turma/gerenciar/' + res.data})
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                })
            } else {
                this.registro.id_turma = parseInt(this.id_turma)
                return axios.post(`${this.apiUrl}turma/alterar`, {...this.registro}).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    } else {
                        this.erro = null
                        this.$router.push({path : '/turma/gerenciar/' + this.id_turma})
                    }
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                })
            }
        },
        async init() {
            this.registro = {
                id_franquia : null,
                domingo     : false,
                segunda     : false,
                terca       : false,
                quarta      : false,
                quinta      : false,
                sexta       : false,
                sabado      : false
            }
            await this.selectCursoListar()
            if(this.id_turma) {
                await this.getTurma()
            }
        }
    },
    watch : {
        'registro.id_franquia' : function () {
            this.selectUsuariosListar()
        }
    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
